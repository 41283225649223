import React, { Component } from 'react';
import PostData from '../posts/uno.json';

import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipeGallery} from 'react-photoswipe';

import { LazyLoadImage } from 'react-lazy-load-image-component';

let galleryImages = [];
let galleryThumbnails = [];
let options = {
    //http://photoswipe.com/documentation/options.html
  };

class Gallerie extends Component {
    state = {
        isOpen: true
    }

    createGrid = () => {

        console.log("create");
        //PostData.reverse();

        
        for(var elem of PostData){
            for(var item of elem.photos){
                
                galleryImages = [...galleryImages, {source: require('../images/' + item)}];
                galleryThumbnails = [...galleryThumbnails, {
                    src: require('../images/' + item),
                    thumbnail: require('../images/thumbs/' + item),
                    w: 2000,
                    h: 1333
                }]
                /*
                imageArray.push(
                    <div key={item} className="col-6 col-sm-4 col-md-3 imageWrapper">
                        <img src={require('../images/' + item)} className="galleryImage"></img>
                    </div>
                )
                */
            }
        }
    }


    getThumbnailContent = (item) => {
        return (
            <div key={item} className="imageWrapper">
            <LazyLoadImage
                src={item.thumbnail} // use normal <img> attributes as props
                className="galleryImage"
                height="200"
                width="133"
                effect="blur"
            />
                {/*<img src={item.thumbnail} className="galleryImage" width={200} height={133}/>*/}
            </div>
        );
      }

    render(){
        galleryThumbnails = [];
        galleryImages = [];
        console.log(galleryThumbnails);
        this.createGrid();
        console.log(galleryThumbnails);
        console.log("render")
        return(
            <div>
                <div className="container">
                    <div className="row galleryBorderPadding gallery">
                        <PhotoSwipeGallery items={galleryThumbnails} options={options} thumbnailContent={this.getThumbnailContent}/>
                    </div>

                </div>
            </div>
        );
    }
}

export default Gallerie;