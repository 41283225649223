import React, {Component} from 'react';
import Button from '@material-ui/core/Button';

import 'react-photoswipe/lib/photoswipe.css';
import {PhotoSwipeGallery} from 'react-photoswipe';

import { LazyLoadImage } from 'react-lazy-load-image-component';

let images = [];
let galleryThumbnails = [];

class BildUndText extends Component{
    state = {
        open: false,
        isOpen: true
      };

    renderText = (name) => {
        let array = [];
        for(var elem of this.props.postData){
            if(name === elem.title){
                for(var i in elem.absaetze){
                    array.push(
                        <div key={i} className="absatz">
                            {elem.absaetze[i]}
                        </div>
                    );
                }
            }
        }
        return array;
    }

    createImageLightbox = (name) => {
        images = [];
        galleryThumbnails = [];
        
        for(var elem of this.props.postData){
            var counter = 0;
            if(name === elem.title){
                for(var i of elem.photos){
                    images = [...images, {source: require('../images/' + i)}];
                    if( counter === 0){
                        galleryThumbnails = [...galleryThumbnails, {
                            src: require('../images/' + i),
                            thumbnail: require('../images/' + i),
                            w: 2000,
                            h: 1333
                        }]
                    } else {
                        galleryThumbnails = [...galleryThumbnails, {
                            src: require('../images/' + i),
                            thumbnail: require('../images/thumbs/' + i),
                            w: 2000,
                            h: 1333
                        }]
                    }
                    counter++;
                }
            }
        }
    }

    getThumbnailContent = (item) => {
        return (
            <div key={item} className=" imageWrapper">
                {/*<img src={item.thumbnail} className="galleryImage" width={200} height={133}/>*/}
                <LazyLoadImage
                    src={item.thumbnail} // use normal <img> attributes as props
                    className="galleryImage"
                    height="200"
                    width="133"
                    effect="blur"
                />
            </div>
        );
      }

    showMore = (index) => {
        this.setState({
            open: index,
          });
    }
    render() {
        galleryThumbnails = [];
        images = [];
        this.createImageLightbox(this.props.mapData.title);
        return(
            <div className="container">
                <div className="row post">
                    <div className={"col-sm-6" + (this.props.i%2 === 0 ? (" order-sm-last") : (" order-sm-first") )}>
                        <PhotoSwipeGallery items={galleryThumbnails} thumbnailContent={this.getThumbnailContent}/>
                    </div>
                    <div className="col-sm-6">
                        <div className="headline">{ this.props.mapData.title }</div>
                        <div className="date">{ this.props.mapData.date }</div>
                        <hr></hr>
                        <div className="content text">
                            { this.props.mapData.short }
                            {this.state.open === this.props.i && (
                                <div>
                                    {this.renderText(this.props.mapData.title)}
                                </div>
                            )}
                                {this.state.open === this.props.i ? (
                                    <Button onClick={() => {this.showMore(false)}} variant="outlined" color="primary" className="moreBtn">
                                        weniger
                                    </Button>
                                ) : (
                                    <Button onClick={() => {this.showMore(this.props.i)}} variant="outlined" color="primary" className="moreBtn">
                                        mehr
                                    </Button>
                                )}
                                
                            
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default BildUndText;