import React, {Component} from 'react';

class Panorama extends Component{
    render() {
        return(
            <div>
                {console.log("panorma")}
            </div>
        );
    }
}

export default Panorama;