import React, { Component } from 'react';
import PostData from '../posts/uno.json';

import Panorama from './Panorama';
import BildUndText from './BildUndText';

import Collage from '../images/assets/collage_1.jpg';


class Post extends Component {
    
    render(){
        return(
            <div className="posts">
                <div className="container">
                    <div className="row post">
                        <div className="col-sm-6">
                        <div class=" imageWrapper">
                            <img src={Collage} className="galleryImage"/>
                        </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="headline">Ein Resümee</div>
                            <div className="date">28.02.2019</div>
                            <hr></hr>
                            <div className="content text">
                            Es war ein extrem gelungener Trip mit vielen eindrucksvollen Orten und netten Menschen. Einzig und allein der Zeitpunkt hätte besser gewählt werden können. Ende März, Anfang April dürfte es wesentlich wärmer sein, weniger chinesische Touristen und noch nicht zu viele andere Touristen. Als Tourist hatte ich das Gefühl, dass es das sicherste Land ist, in dem ich je gereist bin. Das ist wohl auch der ständigen Kontrolle und Überwachung zu „verdanken“, welche ein eigenes Kapitel für sich ist. 
                            <br></br>
                            <br></br>
                            <b>Eine kleine Liste an interessanten Dingen die mir während unserer Reise aufgefallen sind:</b>
                                <ul>
                                    <li> 	Translator Apps sind dein bester Freund</li>
                                    <li> 	Kinder können am Besten Englisch</li>
                                    <li> 	Dumplinge und Rice Noodle Soup sind billiges und super gutes Essen</li>
                                    <li> 	Aussteigen lassen bei der Ubahn gibt’s nicht </li>
                                    <li> 	Jeder Winkel wird per Kamera Überwacht</li>
                                    <li> 	Bei jedem Ubahnabgang gibt es Security Checks wie am Flughafen</li>
                                    <li> 	Man fühlt sich extrem sicher</li>
                                    <li> 	Ubahn-Ankunft wird in Sekunden Genauigkeit angezeigt</li>
                                    <li> 	Gerade Zahlen bringen Glück, 4 aber nicht weils das gleiche Wort für Tod ist</li>
                                    <li> 	Warteschlangen werden ignoriert</li>
                                    <li> 	Auf den Boden spucken ist Gang und Gäbe</li>
                                    <li> 	Im Februar ist man der einzige ausländische Tourist</li>
                                    <li> 	Chinesisches Neujahr ist ein Familienfest, auf den Straßen ist wenig los</li>
                                    <li> 	In Restaurants ist es im Winter immer mega kalt, weil die Türen einfach offen sind</li>
                                    <li> 	Smog ist ein Problem das man nicht sieht oder spürt </li>
                                    <li> 	Elektroroller sind überall</li>
                                    <li> 	WeChat Pay/AliPay hat Bargeldbezahlung abgelöst</li>
                                    <li> 	Auf Märkten sollte man 20% des Startpreises als Zielpreis anpeilen</li>
                                    <li> 	Wir haben kaum Bettler gesehen</li>
                                    <li> 	Der unterste Meter aller Bäume ist weiß angemalt zum Schutz gegen Insekten und weil es schön aussieht</li>
                                    <li>Taxi unbedingt mit Meter fahren</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row post">
                        <div className="col-sm-6">
                            <iframe width="100%" height="315" src="https://www.youtube.com/embed/n2w32qlD6SE" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                        <div className="col-sm-6">
                            <div className="headline">China aus der Luft</div>
                            <div className="date">26.02.2019</div>
                            <hr></hr>
                            <div className="content text">
                                Kurz vor der Reise habe ich mir eine DJI Mavic Air Drohne gekauft. Ein Großteil meiner Verwandten war stark davon überzeugt, dass ich als Spion verhaftet werden würde. Öfters wurde ich darauf hingewiesen, dass ich an diesem Ort nicht fliegen darf (was vermutlich nicht einmal wahr war) aber wirkliche Probleme habe ich nie bekommen. Hier ist ein kleiner Zusammenschnitt meiner Videoaufnahmen :)
                                
                            </div>
                        </div>
                    </div>
                </div>
                {PostData.map((mapData, i) => {
                    return(
                        <div key={i}>
                            {console.log("post i", i)}
                            {mapData.typeOfPost === "panorama" && (<Panorama mapData={mapData} i={i} postData={PostData}/>)}
                            {mapData.typeOfPost === "normal" && (<BildUndText mapData={mapData} i={i} postData={PostData}/>)}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default Post;